.button {
  background-color: transparent;
  padding: 10px 5px;
  font-size: 1.4777rem;
  color: $text-color;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  border: 3px solid transparent;
  cursor: pointer;
}

.primary-button {
  border-color: $primary-color;
  color: $primary-color;

  &.contained {
    background-color: $primary-color;
    color: $default-color;
  }
}

.secondary-button {
  border-color: $secondary-color;
  color: $secondary-color;

  &.contained {
    background-color: $secondary-color;
    color: $default-color;
  }
}

.third-button {
  border-color: $text-color;
  color: $text-color;

  &.contained {
    background-color: $text-color;
    color: $default-color;
  }
}

.fifth-button {
  border-color: $fifth-color;
  color: $fifth-color;

  &.contained {
    background-color: $fifth-color;
    color: $text-color;
  }
}

@media only screen and (max-width: map_get($grid-breakpoints, 'xl')) {
  .button {
    font-size: 1.25rem;
  }
}
