$grid-breakpoints: (
  xs: 479px,
  sm: 575px,
  md: 767px,
  lg: 991px,
  xl: 1199px,
  xxl: 1439px,
);

$default-color: #fff;
$text-color: #25363d;
$primary-color: #277a66;
$secondary-color: #dcc785;
$third-color: #bdbbba;
$fourth-color: #ececec;
$fifth-color: #bcbcbc;
$sixth-color: #6c6a6a;

$main-font: 'Dolce Vita', sans-serif;
$font-size: 18px;
