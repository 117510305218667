.page-header {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  left: 0;
  background-color: $default-color;
  width: 100%;
  border-bottom: 3px solid $secondary-color;
  z-index: 1000;

  .container {
    display: flex;
    justify-content: space-between;
  }

  &__logo {
    width: 100%;
    max-width: 535px;
    margin-right: 20px;
  }

  &__menu {
    display: flex;

    &-item {
      position: relative;
      display: flex;
      align-items: center;
      padding: 50px 60px 50px;
      font-size: 1.4777rem;
      font-weight: 300;
      letter-spacing: 0.2rem;
      text-transform: uppercase;

      &--state-active a {
        color: $primary-color;
      }

      &.open {
        & > .dropdown {
          display: block;
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  .login-button {
    width: 100%;
    margin: 20px 0 0;
    padding: 25px;
  }

  .dropdown {
    min-width: 480px;

    input {
      margin: 10px 0;
    }

    .password-input {
      border-color: $third-color;
    }

    &__forgot-password {
      margin: 20px 0;
      letter-spacing: 0.2rem;
      color: $third-color;
      text-transform: none;
      font-size: 1rem;
      font-weight: bold;
      font-family: 'Arial', sans-serif;
      line-height: 1.3;

      a {
        text-decoration: underline;
      }
    }

    &__title {
      margin-bottom: 20px;
      text-align: center;

      span {
        font-weight: bold;
      }
    }

    .product-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0;
      color: $primary-color;

      .flex-wrapper {
        display: flex;
        align-items: center;
        margin-right: 30px;
      }

      &__image {
        width: 80px;
        height: 80px;
        flex-shrink: 0;
        margin-right: 10px;
      }

      &__name {
        line-height: 1.2;
        font-weight: bold;
        font-size: 1.2rem;
      }

      &__count {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 45px;
        height: 45px;
        padding: 5px;
        font-size: 1.2rem;
        font-weight: bold;
        line-height: 1;
        letter-spacing: normal;
        color: $primary-color;
        border: 3px solid $primary-color;
      }
    }

    &__total-price {
      margin: 20px 0 30px;
      text-align: center;

      span {
        font-weight: bold;
      }
    }

    &__button {
      position: relative;
      width: 100%;
      padding: 30px 20px;

      &:after {
        content: '';
        position: absolute;
        top: 10px;
        bottom: 10px;
        right: 10px;
        left: 10px;
        border: 1px solid $primary-color;
      }
    }
  }

  &__search {
    display: flex;
    align-self: center;
    min-width: 300px;

    .search-icon {
      position: relative;
      width: 56px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:after {
        content: '';
        position: absolute;
        top: 8px;
        left: 5px;
        bottom: 8px;
        right: 5px;
        background: url('../assets/icons/search.png') no-repeat;
        background-size: contain;
        background-position: center;
      }
    }
  }

  .open-search {
    display: none;
  }

  .search-wrapper {
    background-color: $fourth-color;
    height: 0;
    border-bottom: 3px solid $secondary-color;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-100%);
    transition: 0.2s;
  }

  .mobile-menu-icon {
    position: relative;
    width: 53px;
    height: 37px;
    display: none;
    flex-shrink: 0;
    padding: 0 20px;
    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url('../assets/icons/menu-icon.png') no-repeat;
      background-size: contain;
    }
  }
}

@media only screen and (max-width: map_get($grid-breakpoints, 'xxl')) {
  .page-header {
    &__logo {
      margin-right: 0;
    }

    &__menu {
      padding: 0 20px;

      &-item {
        padding-left: 25px;
        padding-right: 25px;
      }
    }
  }
}

@media only screen and (max-width: map_get($grid-breakpoints, 'xl')) {
  .page-header {
    &__logo {
      max-width: 285px;
    }

    &__menu {
      &-item {
        padding: 45px 30px 40px;
        font-size: 1.333rem;
      }
    }

    .search-wrapper {
      &--state-visible {
        padding: 25px 40px;
        height: auto;
        transform: translateY(0);
        visibility: visible;
        opacity: 1;
      }

      .page-header__search {
        display: flex;
        max-width: 280px;
        min-width: auto;
        margin: auto;

        input {
          padding: 7px 10px;
        }

        .search-icon {
          width: 50px;
          padding: 0;

          &:after {
            left: 3px;
            right: 3px;
            bottom: 3px;
            top: 3px;
          }
        }
      }
    }

    &__search {
      display: none;

      &.open-search {
        display: block;
        min-width: auto;

        .search-icon {
          background-color: $default-color;
          width: 43px;
          height: 43px;

          &:after {
            left: 4px;
            right: 4px;
            bottom: 4px;
            top: 4px;
            background-image: url('../assets/icons/search-beige.png');
          }
        }
      }
    }
  }
}

@media only screen and (max-width: map_get($grid-breakpoints, 'lg')) {
  .page-header {
    &__menu-item {
      padding: 30px 20px;
      font-size: 1.2rem;
    }

    .dropdown {
      position: absolute;
      background-color: $fourth-color;
      min-width: 400px;
      margin-top: 3px;
    }
  }
}

@media only screen and (max-width: map_get($grid-breakpoints, 'md')) {
  .page-header {
    .container {
      align-items: center;
      padding-top: 25px;
      padding-bottom: 25px;
    }

    &__logo {
      width: 55px;
      flex-shrink: 0;
    }

    &__menu {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      background-color: $fourth-color;
      flex-direction: column;
      height: 0;
      margin-top: 3px;
      padding: 20px 0;
      visibility: hidden;
      opacity: 0;
      transform: scaleY(0);
      transform-origin: top center;
      transition: 0.2s;

      &--state-open {
        height: auto;
        visibility: visible;
        opacity: 1;
        transform: scaleY(1);
      }

      &-item {
        padding: 15px 0;
        flex-direction: column;
      }
    }

    .dropdown {
      position: static;
      max-width: 400px;
      min-width: auto;
      margin-top: 10px;

      .product-item {
        &__name {
          font-size: 1rem;
        }

        &__image {
          width: 50px;
          height: 50px;
        }

        &__count {
          width: 40px;
          height: 40px;
        }
      }

      &__button {
        padding: 20px;
      }
    }

    .mobile-menu-icon {
      display: flex;
    }

    .login-button {
      padding: 20px;
    }
  }
}

@media only screen and (max-width: map_get($grid-breakpoints, 'sm')) {
  .page-header {
    .search-wrapper {
      &--state-visible {
        padding: 20px 30px;
      }
    }
  }
}
