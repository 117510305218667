$products-gap: 30px;

.reviews {
  &-text {
    margin: 5px 8px 5px 0;
    font-size: 1.185183rem;

    span {
      color: $primary-color;
    }
  }

  &-inner-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 25px;
  }

  &-rating {
    display: flex;
    line-height: 1;
  }

  &-arrow {
    position: relative;
    width: 27px;
    height: 15px;
    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      background: url('../../../assets/icons/arrow.png') no-repeat;
      background-size: contain;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }

  &-dropdown {
    height: 0;
    transform: scaleY(0);
    visibility: hidden;
    border-top: 1px solid $fifth-color;
    transform-origin: top center;

    &__comment-form {
      display: none;

      input,
      textarea {
        margin: 10px 0;
        border: 1px solid $fifth-color;
        font-size: 1rem;
      }

      textarea {
        padding: 10px 14px;
      }

      button {
        padding: 10px 20px;
        font-size: 1rem;
      }
    }

    &__add-new-review {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        background-color: $fifth-color;
        width: 100%;
        height: 1px;
      }

      .add-new-review__icon {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        margin: 0 auto;
        border: 1px solid $fifth-color;
        border-top: none;
        cursor: pointer;

        &:before,
        &:after {
          content: '';
          position: absolute;
          background-color: $fifth-color;
          width: 25px;
          height: 2px;
        }

        &:before {
          transform: rotate(90deg);
        }
      }
    }

    &__item {
      padding: 20px 0;

      .inner-wrapper {
        display: flex;
        justify-content: space-between;
      }

      &-title {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        color: $primary-color;
        font-size: 1.185183rem;

        p {
          margin-left: 10px;
        }
      }

      &-text {
        margin-top: 10px;
        line-height: 1.5;
        font-family: 'Arial', sans-serif;
      }

      &-info {
        display: flex;
        align-items: center;
        margin-left: 15px;
        font-family: 'Arial', sans-serif;
        font-style: italic;
      }

      &-date {
        margin-right: 5px;
        color: $fifth-color;
      }
    }
  }
}

.product-post-page {
  .product {
    display: flex;
    margin-bottom: 92px;
    padding-top: 15px;

    &__slider {
      &-wrapper {
        padding-bottom: 20px;
      }

      .slider-nav-thumbnails {
        width: 213px;
        margin-right: 45px;
        margin-bottom: 0;
        float: left;
        z-index: 1;

        .slick-current {
          opacity: 0.5;
        }

        .thumbnails-item {
          display: inline-flex;
          margin: 10px 0;
          aspect-ratio: 1/1;

          &:first-child {
            margin-top: 0;
          }

          img {
            object-fit: cover;
          }
        }
      }
    }

    &__gallery {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: right;

      &-share {
        display: inline-flex;
        align-self: flex-end;
        border: 1px solid $fifth-color;
        border-right: 0;
      }

      .share {
        &-title {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 30px;
          font-size: 1.18518rem;
          letter-spacing: 0.2em;
        }

        &-item {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          width: 55px;
          height: 55px;
          padding: 10px;
          border-left: 1px solid $fifth-color;
          border-right: 1px solid $fifth-color;
        }
      }
    }

    &__info {
      width: 100%;
      max-width: 1170px;
      margin-left: 50px;

      &-title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 60px;

        h1 {
          letter-spacing: 0.4rem;
          color: $primary-color;
          font-size: 2.96rem;
          font-family: 'Dolce Vita Bold';
          font-weight: bold;
        }
      }

      &-price {
        margin-left: 20px;
        padding: 10px 10px 10px 17px;
        letter-spacing: 0.2rem;
        text-align: center;
        font-family: 'Dolce Vita Bold';
        font-size: 1.777rem;
        border: 3px solid $primary-color;
        color: $primary-color;
        line-height: 1;
      }

      &-subtitle {
        font-size: 1.185185rem;
        color: $third-color;
        letter-spacing: 0.4rem;
      }

      &-description {
        margin-bottom: 50px;
        font-size: 1.185185rem;
        line-height: 1.6;
        letter-spacing: 0.2rem;
      }

      &-colors {
        display: flex;
        align-items: center;
        margin-bottom: 47px;
      }

      .colors {
        &-items {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        }

        &-item {
          margin: 3px 0 3px 10px;
          width: 27px;
          height: 27px;
          border-radius: 100%;
        }
      }

      &-quality {
        display: flex;
        align-items: center;
        margin-bottom: 60px;
      }

      .quality {
        &-value {
          width: 80px;
          margin-left: 18px;
          padding: 8px 8px 8px 16px;
          text-align: center;
          border: 1px solid $fifth-color;
          color: $fifth-color;
          font-size: 1.4914rem;
          font-family: 'Dolce Vita Bold';
        }
      }

      &-subscription {
        width: 100%;
        margin-bottom: 38px;
        padding: 40px;
        border: 1px solid $fifth-color;
      }

      .subscription {
        &-title {
          margin-bottom: 5px;
          color: $text-color;
          font-family: $main-font;

          span {
            font-family: 'Dolce Vita Light';
          }
        }

        &-form {
          max-width: 556px;
          display: flex;
          border: 1px solid $text-color;

          input {
            font-family: Arial, sans-serif;
            font-style: italic;
            text-transform: lowercase;
            border-width: 1px;
          }
        }
      }

      &-reviews {
        align-self: flex-end;
        margin-top: 50px;
        border: 1px solid $fifth-color;

        &.mobile {
          display: none;
        }

        .flex-wrapper {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin-right: 20px;
        }

        .star {
          position: relative;
          width: 23px;
          height: 26px;
          margin-left: 8px;

          &:first-child {
            margin: 0;
          }

          &:after {
            content: '';
            position: absolute;
            background: url('../../../assets/icons/outlined-star.png') no-repeat;
            background-size: contain;
            width: 100%;
            height: 100%;
          }

          &--state-fill:after {
            background: url('../../../assets/icons/fill-star.png') no-repeat;
            background-size: contain;
          }
        }

        &--state-open {
          .reviews-dropdown {
            height: auto;
            padding: 0 25px 30px;
            visibility: visible;
            transform: scaleY(1);
          }

          .reviews-arrow {
            transform: rotate(180deg);
          }
        }
      }
    }

    .buttons-wrapper {
      width: 100%;
      display: flex;

      &--state-mobile {
        display: none;
      }
    }

    .wish-button {
      width: 50%;
      padding: 45px 35px;
      font-family: 'Dolce Vita Bold';
      border: 1px solid $fifth-color;
    }

    .card-button {
      width: 50%;
      margin-left: 53px;
      padding: 45px 25px;
      font-size: 1.851851rem;
      font-family: 'Dolce Vita Bold';
    }
  }

  .products-list {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0 104px #{-$products-gap};

    &__item {
      position: relative;
      width: 20%;
      min-width: 250px;
      flex: 0 1 calc(20% - #{$products-gap});
      margin: $products-gap 0 0 $products-gap;
      border: 1px solid $third-color;
      aspect-ratio: 1 / 1;

      &-details {
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.6);
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: $primary-color;
        text-align: center;
        transition: 0.3s;
        opacity: 0;

        .details {
          &-title {
            line-height: 1.2;
            font-size: 2rem;
            font-weight: bold;
          }

          &-button {
            margin: 10px auto 10px;
            padding: 10px 20px;
          }

          &-price {
            font-weight: bold;
          }
        }
      }

      &:hover > .products-list__item-details {
        opacity: 1;
      }
    }
  }

  .list-categories {
    max-width: 1960px;
    display: grid;
    gap: 27px;
    grid-template-columns: repeat(2, 1fr);
    margin: 57px auto;
    grid-auto-rows: 470px;
    grid-auto-flow: row dense;

    &__item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      grid-row: span 2;
      text-align: center;

      &:nth-child(-n + 2),
      &:nth-child(4) {
        grid-row: span 1;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.2);
        width: 100%;
        height: 100%;
      }

      img {
        object-fit: cover;
      }

      &-text {
        position: absolute;
        max-width: 90%;
        padding: 50px;
        color: $default-color;
        border: 3px solid $default-color;
        letter-spacing: 0.4rem;
        text-align: center;
        z-index: 1;
      }

      .category-item {
        &-title {
          font-size: 2.96rem;
          font-weight: bold;
        }

        &-subtitle {
          color: $default-color;
        }
      }
    }
  }

  .brands {
    margin-bottom: 55px;

    &-carousel {
      padding: 50px 25px;

      &__item {
        width: 160px;
        margin: 0 10px;
        aspect-ratio: 1/1;
      }

      .slick-arrow {
        width: 21px;
        height: 41px;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          background: url('../../../assets/icons/slider-arrow.png') no-repeat;
          background-size: 100% 100%;
          width: 100%;
          height: 100%;
        }
      }

      .slick-next {
        right: 0;

        &:before {
          transform: rotate(180deg);
        }
      }

      .slick-prev {
        left: 0;
      }
    }
  }
}

@media only screen and (max-width: 2000px) {
  .product-post-page .product__slider .slider-nav-thumbnails {
    width: 160px;
    margin-right: 25px;
  }
}

@media only screen and (max-width: map_get($grid-breakpoints, 'xxl')) {
  .product-post-page {
    .reviews-dropdown__item {
      &-title {
        flex-direction: column;
        align-items: flex-start;

        p {
          margin: 10px 0 0;
        }
      }

      &-info {
        margin: 10px 0 0;
      }

      .inner-wrapper {
        flex-direction: column;
      }
    }

    .product {
      &__gallery {
        width: 55%;
      }

      &__slider .slider-nav-thumbnails {
        width: 90px;
        margin-right: 10px;
      }

      &__info {
        margin-left: 30px;

        &-title {
          margin-bottom: 25px;

          h1 {
            font-size: 2rem;
          }
        }

        &-description {
          margin-bottom: 28px;
        }

        &-quality,
        &-colors {
          margin-bottom: 20px;
        }

        &-reviews {
          margin-top: 35px;
        }

        .subscription-title {
          font-size: 1.2rem;
        }
      }

      .buttons-wrapper button {
        font-size: 1.2rem;
      }

      .card-button {
        margin-left: 20px;
      }
    }
  }
}

@media only screen and (max-width: map_get($grid-breakpoints, 'xl')) {
  .product-post-page {
    .reviews {
      &-text {
        font-size: 1rem;
      }

      &-inner-wrapper {
        padding: 16px;
      }
    }

    .product {
      margin-bottom: 40px;

      &__gallery {
        &-share {
          width: 100%;
          margin-top: 35px;
        }

        .share {
          &-title {
            width: 100%;
            font-size: 1rem;
          }

          &-item {
            width: 41px;
            height: 41px;
          }
        }
      }

      &__info {
        &-title {
          flex-direction: column;
          align-items: flex-start;

          h1 {
            font-size: 2.5rem;
          }
        }

        &-price {
          padding: 5px 5px 5px 12px;
          margin: 20px 0 0;
          font-size: 1.5rem;
        }

        &-subtitle {
          font-size: 1rem;
        }

        &-description {
          font-size: 1rem;
        }

        &-subscription {
          padding: 16px;

          input {
            font-size: 1rem;
          }
        }

        &-reviews {
          .star {
            width: 18px;
            height: 21px;
          }

          &--state-open {
            .reviews-dropdown {
              padding: 0 16px 30px;
            }
          }
        }

        .subscription-title {
          font-size: 1rem;
        }
      }

      .buttons-wrapper {
        display: none;

        &--state-mobile {
          display: flex;
        }

        button {
          padding: 24px;
          font-size: 1.33333rem;
        }
      }
    }

    .products-list__item {
      width: 33.3333%;
      flex-basis: calc(33.3333% - #{$products-gap});
    }

    .list-categories {
      grid-auto-rows: 237px;
      margin: 40px 0 0;
      gap: 14px;

      &__item {
        .category-item {
          &-title {
            font-size: 1.444rem;
          }

          &-subtitle {
            font-size: 0.7222rem;
          }
        }

        &-text {
          padding: 23px 30px;
        }
      }
    }

    .brands {
      margin-bottom: 40px;

      &-carousel {
        padding: 40px 25px;
      }
    }
  }
}

@media only screen and (max-width: map_get($grid-breakpoints, 'lg')) {
  .product-post-page {
    .product {
      flex-direction: column-reverse;
      padding-top: 0;

      &__gallery {
        width: 100%;
        margin-top: 40px;
      }

      &__info {
        margin: 0;

        &-reviews {
          display: none;

          &.mobile {
            width: 100%;
            display: block;
          }
        }
      }
    }

    .products-list {
      justify-content: center;
    }
  }
}

@media only screen and (max-width: map_get($grid-breakpoints, 'md')) {
  .product-post-page {
    .product {
      &__slider {
        display: flex;
        flex-direction: column-reverse;

        .slider-nav-thumbnails {
          width: auto;
          margin: 0 -5px;
          float: none;

          .thumbnails-item {
            margin: 10px 5px 0;
          }
        }
      }

      &__gallery-share {
        margin-top: 40px;
        flex-wrap: wrap;
        border: none;
      }

      .share {
        &-title {
          padding: 12px;
          border: 1px solid $fifth-color;
        }

        &-item {
          width: 20%;
          border-bottom: 1px solid $fifth-color;
        }
      }

      &__info {
        &-title h1 {
          font-size: 2rem;
        }

        &-subscription {
          margin-bottom: 20px;
        }
      }

      .buttons-wrapper {
        flex-direction: column-reverse;
        margin-top: 25px;

        button {
          width: 100%;
          min-height: 110px;
        }
      }

      .card-button {
        margin: 0 0 22px;
      }
    }

    .products-list {
      margin-bottom: 48px;
    }

    .list-categories {
      grid-template-columns: 1fr;
      grid-auto-rows: 210px;
      margin: 27px 0 0;
      gap: 27px;

      &__item {
        &:nth-child(-n + 2),
        &:nth-child(6) {
          grid-row: span 1;
        }

        &:nth-child(2) {
          grid-row: span 2;
        }
      }
    }

    .brands {
      margin-bottom: 25px;
    }
  }
}

@media only screen and (max-width: map_get($grid-breakpoints, 'sm')) {
  .product-post-page .products-list__item {
    width: 50%;
    flex-basis: 50%;
  }

  .brands {
    margin-bottom: 25px;

    &-carousel {
      padding: 25px;

      &__item {
        margin: 0 5px;
      }
    }
  }
}

@media only screen and (max-width: map_get($grid-breakpoints, 'xs')) {
  .product-post-page .products-list {
    &__item {
      width: 100%;
      flex-basis: 100%;
    }
  }
}
