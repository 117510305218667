.social-links {
  padding: 30px 0;
  border-bottom: 3px solid $secondary-color;
  text-align: center;

  &__title {
    margin-bottom: 26px;
  }

  &__wrapper {
    display: flex;
    justify-content: center;
  }

  &__item {
    width: 53px;
    height: 53px;
    margin: 0 11px;
    flex-shrink: 0;
  }
}

@media only screen and (max-width: map_get($grid-breakpoints, 'xl')) {
  .social-links {
    padding: 40px 0;

    &__title {
      margin-bottom: 20px;
    }

    &__item {
      width: 43px;
      height: 43px;
    }
  }
}

@media only screen and (max-width: map_get($grid-breakpoints, 'sm')) {
  .social-links {
    padding: 30px 0 40px;
  }
}
