@font-face {
  font-family: 'Dolce Vita';
  src: url('../../assets/fonts/dolce-vita.ttf') format('opentype');
}
@font-face {
  font-family: 'Dolce Vita Bold';
  src: url('../../assets/fonts/dolce-vita-heavy-bold.ttf') format('opentype');
}
@font-face {
  font-family: 'Dolce Vita Light';
  src: url('../../assets/fonts/dolce-vita-light.ttf') format('opentype');
}
