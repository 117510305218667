.advantages {
  padding: 140px 0 90px;
  border-bottom: 3px solid $secondary-color;

  .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__item {
    width: 25%;
    padding: 20px 60px;
    text-align: center;

    &-icon {
      width: 83px;
      height: 83px;
      margin: 0 auto 28px;
    }

    &-title {
      text-transform: uppercase;
      color: $secondary-color;
      font-size: 2.222rem;
      letter-spacing: 0.4rem;
    }

    &-description {
      text-transform: uppercase;
      font-weight: 300;
      letter-spacing: 0.2rem;
    }
  }
}

@media only screen and (max-width: map_get($grid-breakpoints, 'xxl')) {
  .advantages {
    &__item {
      padding: 20px;

      &-icon {
        width: 83px;
        height: 83px;
      }
    }
  }
}

@media only screen and (max-width: map_get($grid-breakpoints, 'xl')) {
  .advantages {
    padding: 45px 0 25px;

    &__item {
      width: 50%;
      padding: 40px 20px;

      &-title {
        font-size: 2.5rem;
      }

      &-description {
        font-size: 1.125rem;
      }
    }
  }
}

@media only screen and (max-width: map_get($grid-breakpoints, 'md')) {
  .advantages {
    &__item {
      width: 50%;
      padding: 40px 20px;

      &-title {
        font-size: 2rem;
      }

      &-description {
        font-size: 1rem;
      }
    }
  }
}

@media only screen and (max-width: map_get($grid-breakpoints, 'sm')) {
  .advantages {
    padding: 0 0 45px;

    &__item {
      width: 100%;
      padding: 90px 0 0;

      &-title {
        font-size: 2.5rem;
      }

      &-description {
        font-size: 1.125rem;
      }
    }
  }
}
