* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

html,
body {
  background: $default-color;
  min-width: 320px;
  height: 100%;
  font-family: $main-font;
  font-size: $font-size;
  font-weight: 400;
  box-sizing: border-box;
  color: $text-color;

  @media only screen and (max-width: map_get($grid-breakpoints, 'xl')) {
    font-size: 16px;
  }
}

.container {
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.page-content {
  flex: 1 0 auto;
}

img {
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

h2 {
  margin: 0;
  text-transform: uppercase;
  font-size: 1.85rem;
}

h3 {
  margin: 0;
  font-size: 1.4777rem;
  font-weight: 300;
  letter-spacing: 0.4rem;
  text-transform: uppercase;
  color: $primary-color;
}

h4 {
  margin: 0;
  font-size: 1.4914rem;
  font-family: 'Dolce Vita Bold';
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  color: $primary-color;
}

p,
span {
  margin: 0;
}

ul {
  margin: 0;
  list-style-type: none;
}

a {
  color: $text-color;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: $primary-color;
  }
}

button {
  font-family: $main-font;
  border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-input-placeholder {
  color: $third-color;
  opacity: 1;
}

input::-moz-placeholder {
  color: $third-color;
  opacity: 1;
}

input:-moz-placeholder {
  color: $third-color;
  opacity: 1;
}

input:-ms-input-placeholder {
  color: $third-color;
  opacity: 1;
}

textarea::-webkit-input-placeholder {
  color: $third-color;
  opacity: 1;
}

textarea:-moz-placeholder {
  color: $third-color;
  opacity: 1;
}

textarea::-ms-input-placeholder {
  color: $third-color;
  opacity: 1;
}

textarea::-moz-placeholder {
  color: $third-color;
  opacity: 1;
}

input,
textarea {
  width: 100%;
  -webkit-appearance: none;
  opacity: 1;
  font-size: 1.4777rem;
  color: $text-color;
  font-family: 'Dolce Vita', sans-serif;
  letter-spacing: 0.2rem;
  border-radius: 0;
}

input {
  padding: 10px 14px;
  border: 3px solid transparent;
  font-weight: 300;
}

.primary-input {
  border-color: $primary-color;
}

.secondary-input {
  border-color: $secondary-color;
}

.third-input {
  border-color: $text-color;
}

textarea {
  width: 100%;
  resize: none;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 20px;
  background-color: $fourth-color;
  display: none;
  margin-top: 3px;
  padding: 20px 30px;
  visibility: hidden;
  opacity: 0;
  transition: 0.4s;
  z-index: 999;
}

.section-title {
  padding: 30px;
  border: 1px solid $fifth-color;
  text-align: center;
  color: $primary-color;
  letter-spacing: 0.4em;
}

.radio-button-container {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 45px;
  font-family: 'Arial', sans-serif;
  font-weight: normal;
  cursor: pointer;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    width: 0;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .checkmark {
      background-color: transparent;
    }

    &:checked ~ .checkmark:after {
      display: block;
    }
  }

  .checkmark:after {
    top: 2px;
    left: 2px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: $primary-color;
  }
}

.checkmark {
  position: absolute;
  top: 50%;
  left: 0;
  background-color: transparent;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  border: 2px solid $fifth-color;
  transform: translateY(-50%);

  &:after {
    content: '';
    position: absolute;
    display: none;
  }
}

@media only screen and (max-width: map_get($grid-breakpoints, 'xl')) {
  h4,
  h3 {
    font-size: 1.333rem;
  }

  input,
  textarea {
    font-size: 1.25rem;
  }

  .section-title {
    h2 {
      font-size: 1.6666rem;
    }
  }
}

@media only screen and (max-width: map_get($grid-breakpoints, 'lg')) {
  .dropdown {
    position: static;
    background-color: transparent;
    display: none;
    margin-top: 0;
  }
}

@media only screen and (max-width: map_get($grid-breakpoints, 'sm')) {
  .container {
    padding: 0 30px;
  }
  .section-title {
    padding: 20px;
  }
}
