.filter {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  border: 1px solid $third-color;
  margin: 40px 0 45px;

  &__item {
    padding: 15px 40px;

    &:not(.filter__item-title) {
      position: relative;
      display: flex;
      align-items: center;

      .filter__item-name {
        position: relative;
        padding-right: 25px;
        color: $text-color;
        cursor: pointer;
        user-select: none;

        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: 50%;
          background: url('../../../assets/icons/arrow.png') no-repeat;
          background-size: contain;
          background-position: center;
          width: 20px;
          height: 100%;
          transform: translateY(-50%);
        }
      }
    }

    .dropdown {
      min-width: 250px;
      left: 3vw;
      margin-top: 1px;
      padding: 10px 30px;
    }

    // This font does not support ellipsis
    &-value {
      display: block;
      padding: 10px 0;
      font-size: 1.2rem;
      letter-spacing: 0.2em;
      overflow: hidden;
      -ms-text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
    }

    &.open {
      .filter__item-name:after {
        transform: translateY(-50%) rotate(180deg);
      }

      & > .dropdown {
        display: block;
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

@media only screen and (max-width: map_get($grid-breakpoints, 'xxl')) {
  .filter {
    margin: 30px 0;
    padding: 0 25px;

    &__item {
      padding: 10px 15px;

      &:after {
        margin-left: 10px;
      }

      &-title {
        position: relative;
        width: 100%;
        text-align: center;

        &:after {
          content: '';
          position: absolute;
          left: -25px;
          bottom: 0;
          right: -25px;
          background-color: $third-color;
          height: 1px;
          margin: 0;
        }
      }

      .dropdown {
        left: 0;
      }

      &.open {
        &:after {
          margin: 0 0 5px 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: map_get($grid-breakpoints, 'lg')) {
  .filter {
    flex-direction: column;
    padding: 0;

    &__item {
      flex-direction: column;
      padding-left: 0;
      padding-right: 0;
      border: 1px solid $third-color;

      &:not(.filter__item-title) {
        align-items: stretch;
        text-align: center;

        .filter__item-name {
          margin: 0 15px;
          padding: 0 25px;
        }
      }

      &-title {
        padding: 10px 25px;

        &:after {
          content: none;
        }
      }

      &:after {
        position: absolute;
        right: 13px;
        margin: 0;
      }

      &:last-child {
        border-bottom: none;
      }

      .dropdown {
        min-width: auto;
        margin: 0;
        padding: 20px 0 0;
        text-align: center;
      }
    }
  }
}
