$grid-gap: 20px;

.products-grid {
  display: grid;
  gap: 45px 0;

  &__row {
    display: flex;
    margin-left: -$grid-gap;

    &:nth-child(2n) {
      flex-direction: row-reverse;
    }

    &.multiline {
      flex-wrap: wrap;
    }

    &-inner-wrapper {
      display: flex;
      flex-wrap: wrap;
      width: 60%;
    }

    &-flex-wrapper {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      .products-grid__item {
        width: 20%;
        aspect-ratio: 1/1;
        flex-basis: calc(20% - #{$grid-gap});
      }
    }
  }

  &__item {
    position: relative;
    background-repeat: no-repeat;
    background-color: $default-color;
    background-size: contain;
    background-position: center;
    width: calc(100% / 3);
    min-width: 250px;
    aspect-ratio: 1/1;
    flex: 1 1 calc((100% / 3) - #{$grid-gap});
    margin: $grid-gap 0 0 $grid-gap;
    border: 1px solid $third-color;
    cursor: pointer;

    &-details {
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.6);
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: $primary-color;
      text-align: center;
      transition: 0.3s;
      opacity: 0;

      .details {
        &-title {
          line-height: 1.2;
          font-size: 2.222rem;
          font-weight: bold;
        }

        &-button {
          margin: 10px auto 10px;
          padding: 10px 20px;
        }

        &-price {
          font-weight: bold;
        }
      }
    }

    &:hover > .products-grid__item-details {
      opacity: 1;
    }

    .product-item {
      &-title {
        font-size: 2.96rem;
        font-weight: bold;
      }

      &-subtitle {
        color: $default-color;
      }
    }

    &-text {
      position: relative;
      padding: 50px;
      color: $default-color;
      border: 3px solid $default-color;
      letter-spacing: 0.4rem;
      text-align: center;
      z-index: 1;
    }

    &--category {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 40%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      padding: 30px;
      aspect-ratio: auto;
      text-align: center;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.2);
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media only screen and (max-width: map_get($grid-breakpoints, 'lg')) {
  .products-grid {
    &__row {
      &-inner-wrapper {
        width: 35%;
      }

      &.multiline {
        flex-direction: column;
        flex-wrap: initial;

        .products-grid__row-inner-wrapper {
          width: 100%;
          margin: 0;
        }

        .products-grid__item {
          width: 33.333%;
          flex: 1 1 calc((100% / 3) - #{$grid-gap});
          padding-bottom: calc(100% / 3);
        }

        .products-grid__item--category {
          width: 100%;
          margin-bottom: $grid-gap;
          padding-bottom: 30px;
        }
      }
    }

    &__item {
      width: 100%;
      min-width: auto;
      flex: 1 1 auto;
      padding-bottom: 100%;

      &-details {
        .details {
          &-title {
            font-size: 2.222rem;
          }

          &-button {
            padding: 10px;
            font-size: 1rem;
          }
        }
      }

      .product-item {
        &-title {
          font-size: 2.96rem;
        }
      }

      &--category {
        width: 65%;
        padding-bottom: 30px;
      }
    }
  }
}

@media only screen and (max-width: map_get($grid-breakpoints, 'sm')) {
  .products-grid {
    gap: 25px 0;

    &__row {
      flex-direction: column;

      &:nth-child(2n) {
        flex-direction: column;
      }

      &-inner-wrapper {
        width: 100%;
        margin: 0;
      }

      &.multiline {
        .products-grid__item {
          width: 50%;
          min-width: 200px;
          flex: 1 1 calc(50% - 30px);
          margin-left: 15px;
          padding-bottom: 50%;
        }

        .products-grid__item--category {
          width: 100%;
          padding-bottom: 30px;
        }
      }
    }

    &__item {
      width: 50%;
      min-width: 250px;
      flex: 1 1 calc(50% - 30px);
      margin-left: 15px;
      padding-bottom: 50%;

      &--category {
        width: auto;
        margin-bottom: $grid-gap;
        padding-bottom: 30px;
      }

      &-text {
        padding: 30px;
      }

      .product-item-title {
        font-size: 2rem;
      }
    }
  }
}

@media only screen and (max-width: map_get($grid-breakpoints, 'xs')) {
  .products-grid {
    &__row.multiline {
      .products-grid__item:not(.products-grid__item--category) {
        padding-bottom: 100%;
      }
    }

    &__item {
      &:not(.products-grid__item--category) {
        padding-bottom: 100%;
      }

      &-text {
        padding: 30px 15px;
      }

      .product-item-title {
        font-size: 1.8rem;
      }
    }
  }
}
