.payment-page {
  &__nav {
    display: flex;
    margin: 20px 0 30px;
    padding-right: 30px;
    overflow: hidden;

    &-item {
      position: relative;
      width: 33.3333%;
      padding: 15px 0 13px 30px;
      line-height: 1;
      text-align: center;
      border-top: 3px solid $fifth-color;
      border-bottom: 3px solid $fifth-color;
      cursor: pointer;

      &:first-child {
        border-left: 3px solid $fifth-color;
      }

      h3 {
        color: $fifth-color;
      }

      &:after,
      &:before {
        content: '';
        position: absolute;
        top: -4px;
        left: 100%;
        width: 0;
        height: 0;
        display: block;
        border-style: solid;
        z-index: 1;
      }

      &:after {
        top: 0;
        border-color: transparent transparent transparent $default-color;
        border-width: 27px;
      }

      &:before {
        border-color: transparent transparent transparent $fifth-color;
        border-width: 31px;
      }

      &--state-previous {
        background-color: rgb(146, 188, 178);
        border-color: transparent;

        &:first-child {
          border-left: 3px solid rgb(146, 188, 178);
        }

        h3 {
          color: $default-color;
        }

        &:before,
        &:after {
          border-color: transparent transparent transparent rgb(146, 188, 178);
        }
      }

      &--state-active {
        background-color: $primary-color;
        border-color: transparent;

        h3 {
          color: $default-color;
        }

        &:first-child {
          border-color: transparent;
        }

        &:after {
          top: -3px;
          border-color: transparent transparent transparent $primary-color;
          border-width: 30px;
        }
      }
    }
  }

  .address {
    width: 100%;
    min-width: 400px;
    max-width: 670px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    h3 {
      padding: 12px 10px 10px;
      color: $primary-color;
      text-align: center;
      font-weight: bold;
    }

    &__box {
      margin-bottom: 30px;
      border: 3px solid $primary-color;
      font-weight: bold;

      &-body {
        padding: 20px 15px;
        border-top: 3px solid $primary-color;

        select {
          background-color: transparent;
          width: 100%;
          padding: 3px 5px;
          font-size: 0.7777rem;
          border-color: $fifth-color;
          color: $text-color;
          text-overflow: ellipsis;
        }

        .add-new-address {
          width: 100%;
          margin-top: 20px;
          font-size: 1rem;
        }
      }
    }
  }

  .info-icon {
    // display: inline-block;
    display: inline-flex;
    align-items: center;
    line-height: 1;
    cursor: pointer;

    &:after {
      content: '?';
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      margin-left: 5px;
      font-size: 1rem;
      color: $fifth-color;
      font-family: 'Dolce Vita Bold';
      border: 2px solid $fifth-color;
      border-radius: 100%;
      letter-spacing: 0;
    }
  }

  .price-info {
    &__body {
      &-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 25px;
        color: $sixth-color;
        letter-spacing: 0.2rem;
        line-height: 1;

        .primary {
          color: $primary-color;
        }

        p:last-child {
          margin-left: 20px;
        }

        .additional-text {
          font-family: 'Arial', sans-serif;
          letter-spacing: 0;
          font-size: 0.7rem;
        }
      }

      .total-title {
        color: $primary-color;
      }

      .total-row {
        margin: 40px 0 15px;
        font-size: 1.3rem;
      }

      &-coupon {
        max-width: 350px;
        display: flex;

        input {
          border-color: $fifth-color;
          font-size: 1rem;
          font-weight: bold;
        }

        button {
          margin-left: -3px;
          padding: 3px 10px;
          font-weight: bold;
          font-size: 1rem;
        }
      }
    }
  }

  .submit-button {
    padding: 30px 20px;
    font-weight: bold;
    font-size: 1.2rem;
  }

  .content {
    display: none;
    align-items: flex-start;

    &--state-active {
      display: flex;
    }
  }

  .pannier {
    .delete-icon {
      position: relative;
      width: 24px;
      height: 24px;
      margin: auto;
      opacity: 0.5;
      cursor: pointer;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background: url('../../../assets/icons/trash.svg') no-repeat;
        background-size: 100% 100%;
        width: 100%;
        height: 100%;
      }

      &:hover {
        opacity: 1;
      }
    }

    &__list-products {
      margin-right: 40px;

      &--state-mobile {
        display: none;
      }

      table {
        border-collapse: collapse;

        img {
          width: 100%;
          height: 100%;
          max-width: 200px;
          max-height: 200px;
          min-width: 150px;
          min-height: 150px;
          margin: auto;
          aspect-ratio: 1/1;
        }
      }

      .price {
        text-align: center;
        font-weight: bold;

        &-primary {
          color: $primary-color;
        }
      }

      td {
        border: 1px solid $fifth-color;

        h3 {
          font-weight: bold;
        }

        p {
          color: $fifth-color;
          font-size: 1.2rem;
          letter-spacing: 0.2rem;
        }

        &:nth-child(2) {
          padding: 15px 2vw;
        }
      }

      .quality-value {
        width: 80px;
        display: flex;
        margin: 0 auto;
        padding: 8px;
        text-align: center;
        border: 1px solid $fifth-color;
        color: $fifth-color;
        font-size: 1.2rem;
        font-family: 'Dolce Vita Bold';
      }

      th {
        padding: 15px 2vw;
        border: 1px solid $fifth-color;
        text-align: center;
        color: $fifth-color;
        letter-spacing: 0.2rem;
        white-space: nowrap;

        &:first-child,
        &:last-child {
          border: none;
        }

        &:last-child {
          padding-left: 25px;
          padding-right: 25px;
        }
      }
    }

    &__price-wrapper {
      .continue-button {
        position: relative;
        width: 100%;
        margin-top: 10px;
        padding: 30px 20px;
        font-weight: bold;
        font-size: 1.2rem;

        &:after {
          content: '';
          position: absolute;
          top: 10px;
          bottom: 10px;
          right: 10px;
          left: 10px;
          border: 1px solid $fifth-color;
        }
      }
    }
  }

  .delivery {
    &__list {
      width: 100%;
      margin-right: 20px;
      border: 3px solid $text-color;
      font-weight: bold;

      h3 {
        padding: 12px 10px 10px;
        color: $text-color;
        text-align: center;
        font-weight: bold;
      }

      &-body {
        padding: 15px;
        border-top: 3px solid $text-color;
      }

      .radio-button-container {
        img {
          max-width: 200px;
          margin: 5px 20px 5px 0;
        }

        span {
          color: $primary-color;
          font-family: $main-font;
          font-weight: bold;
          letter-spacing: 0.4rem;
        }
      }
    }

    .list-row {
      width: 100%;
      display: inline-block;
      margin: 5px 0;
    }

    .address-choice {
      p {
        margin-bottom: 5px;
        letter-spacing: 0.2rem;
        color: $sixth-color;
      }

      .radio-button-wrapper {
        margin: 20px 0 30px;
        padding-left: 40px;

        .radio-button-container {
          padding-left: 25px;
        }
      }

      .assurance {
        margin-bottom: 20px;

        h3 {
          padding: 0;
          letter-spacing: 0.1rem;
          color: $sixth-color;
          font-family: 'Dolce Vita Bold';

          span {
            color: $primary-color;
          }
        }

        p {
          letter-spacing: 0;
          font-weight: 500;
          color: $sixth-color;
        }
      }
    }
  }

  .payment {
    .user-personal-data {
      display: flex;
      flex-direction: column;
      margin-top: 5px;
      padding-left: 20px;
      font-size: 1rem;
      line-height: 1.2;
      font-weight: normal;
      letter-spacing: normal;
      font-family: 'Arial', sans-serif;
    }

    &-item {
      width: 100%;
      display: inline-block;
      margin: 30px 0;

      img {
        width: 100%;
        max-width: 250px;
      }
    }

    &__list {
      width: 100%;
      margin-right: 40px;
      border: 3px solid $primary-color;

      h3 {
        padding: 12px 10px 10px;
        text-align: center;
        font-weight: bold;
      }

      &-body {
        padding: 15px;
        border-top: 3px solid $primary-color;
      }
    }

    .address__conditions {
      margin-bottom: 10px;
      color: $sixth-color;
      font-family: 'Arial', sans-serif;
      text-align: justify;

      a {
        text-decoration: underline;
        color: $sixth-color;
      }
    }

    .total-row {
      margin: 0;
    }
  }
}

@media only screen and (max-width: map_get($grid-breakpoints, 'xxl')) {
  .payment-page {
    .pannier {
      flex-direction: column;

      &__list-products {
        width: 100%;
        margin: 0 0 30px;
        overflow: visible;

        table {
          width: 100%;
        }
      }

      .address {
        max-width: 700px;
      }
    }

    .delivery {
      flex-direction: column;

      &__list {
        margin: 0 0 30px;
      }
    }

    .payment {
      flex-direction: column;

      &__list {
        margin: 0 0 30px;
      }
    }
  }
}

@media only screen and (max-width: map_get($grid-breakpoints, 'xl')) {
  .payment-page__nav-item {
    &:before {
      border-width: 28px;
    }

    &:after {
      border-width: 24px;
    }

    &--state-active {
      &:after {
        border-width: 27px;
      }
    }
  }
}

@media only screen and (max-width: map_get($grid-breakpoints, 'lg')) {
  .payment-page .pannier__list-products table {
    th {
      white-space: normal;
    }

    img {
      width: 150px;
      height: 150px;
    }
  }
}

@media only screen and (max-width: map_get($grid-breakpoints, 'md')) {
  .payment-page {
    &__nav {
      flex-direction: column;
      padding: 0;

      &-item {
        width: 100%;
        padding: 15px;
        border: 3px solid $fifth-color;
        border-top: none;

        &:first-child {
          border-top: 3px solid $fifth-color;
        }

        &:after,
        &:before {
          content: none;
        }

        &--state-active {
          border-color: transparent;

          &:first-child {
            border-color: transparent;
          }
        }
      }
    }

    .pannier {
      &__list-products {
        display: none;

        &--state-mobile {
          display: block;
        }

        &-item {
          display: inline-block;
          width: 100%;
          margin: 10px 0;
          border: 1px solid $fifth-color;
          font-weight: bold;
          color: $fifth-color;
          letter-spacing: 0.2rem;

          &__title {
            position: relative;
            padding: 15px 45px 15px 15px;

            &:after {
              content: '';
              position: absolute;
              right: 15px;
              top: 50%;
              background: url('../../../assets/icons/arrow.png') no-repeat;
              background-size: contain;
              width: 20px;
              height: 10px;
              transform: translateY(-50%);
            }
          }

          .dropdown-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px;
            border-top: 1px solid $fifth-color;

            .price {
              margin-left: 20px;
              text-align: right;
            }

            .quality-value {
              margin: 0 0 0 20px;
            }
          }

          .list-products-item__dropdown {
            display: none;
          }

          &--state-open {
            h3:after {
              transform: translateY(-50%) rotate(180deg);
            }
            .list-products-item__dropdown {
              display: block;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: map_get($grid-breakpoints, 'xs')) {
  .payment-page {
    .pannier__price-wrapper {
      min-width: auto;

      .price-info__body .total-row {
        font-size: 1rem;
      }
    }

    .address {
      min-width: auto;
    }

    .delivery {
      .address-choice .radio-button-wrapper {
        padding-left: 0;
      }
    }
  }
}
