.page-footer {
  padding: 40px 50px 60px;
  flex: 0 0 auto;

  .container {
    display: flex;
    justify-content: space-between;
  }

  &__item {
    width: 20%;
    padding: 0 10px;
    text-align: center;

    &-title {
      margin-bottom: 20px;
    }

    &-link {
      display: block;
      text-transform: uppercase;
      font-weight: 300;
      letter-spacing: 0.2rem;
    }

    &:first-child {
      order: 1;
    }
  }

  &__newsletter {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: map_get($grid-breakpoints, 'xxl')) {
  .page-footer {
    padding-left: 0;
    padding-right: 0;

    .container {
      flex-wrap: wrap;
    }

    &__item {
      width: 25%;

      &:first-child {
        width: 100%;
        order: initial;
      }
    }

    &__newsletter {
      max-width: 385px;
      margin: 0 auto 72px;
    }
  }
}

@media only screen and (max-width: map_get($grid-breakpoints, 'xl')) {
  .page-footer {
    &__newsletter {
      max-width: 285px;

      input {
        font-size: 1rem;
      }
    }
  }
}

@media only screen and (max-width: map_get($grid-breakpoints, 'lg')) {
  .page-footer {
    padding: 0 0 20px;

    &__item {
      width: 50%;
      padding: 40px 10px;
    }

    &__newsletter {
      margin-bottom: 0;
    }
  }
}

@media only screen and (max-width: map_get($grid-breakpoints, 'xs')) {
  .page-footer {
    padding: 0 0 30px;

    &__item {
      width: 100%;
      padding: 40px 0 0;
    }

    &__newsletter {
      margin-bottom: 35px;
    }
  }
}
